import React from "react";
import "./TextBannerPotato.css";
import {
  RFarm_Title_2,
  RFarm_Title,
  RFarm_poplarTileTitle_2,
  LifeMemberPau,
} from "./GerenicView.js";

function TextBannerPotato() {
  return (
    // <span className= "rajiFarmTile1_Span"> Raji Farm </span>
    <div className="TextBannerAttributePotato">
      <marquee direction="left">
        Booking of  🥔 Potato 🥔 Rashan and seeds 
        <span className="clones"> (Diamond, columbo, Pukhraj )</span> is started
      </marquee>
    </div>
  );
}

//   function RFarm_Title_2() {
//     return (
//       <span className= "clones"> Raji Farm </span>
//     )
//   }

export { TextBannerPotato };
