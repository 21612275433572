// MainContent.js
import React from 'react';

import SettingsView from '../NestedFolders/SettingsView.js';
import DashboardView from '../NestedFolders/DashboardView.js';
import AddNewBill from '../NestedFolders/AddNewBill.js';

const MainContent = ({ selectedView }) => {
  return (
    <div className="main-content">
    {selectedView === 'dashboard' ? <DashboardView /> : null}
    {selectedView === 'settings' ? <SettingsView /> : null}
    {selectedView === 'addNewBill' ? <AddNewBill /> : null}
  </div>

  );
};

export default MainContent;
