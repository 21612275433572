import React from 'react';


const SettingsView = () => (
    <div>
      <h2>Settings View</h2>
      {/* Add settings content here */}
    </div>
  );

  // export default Admin;
export default SettingsView;

