import {React , useState} from 'react';
// Dashboard
import Sidebar from '../Dashboardapp/MainScreens/Sidebar.js';
import MainContent from '../Dashboardapp/MainScreens/MainContent.js';



import './Dashboard.css';


const Dashboard = () => {

  const [selectedView, setSelectedView] = useState('dashboard');

  const handleSelectView = (view) => {
    setSelectedView(view);
  };

  return (
    <div className="dashboard">
   <Sidebar onSelectView={handleSelectView} />
      <MainContent selectedView={selectedView} />
  </div>
  );
};

export default Dashboard;