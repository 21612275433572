// Sidebar.js
import {React,useState} from 'react';

const Sidebar = ({ onSelectView }) => {

    const [selectedView, setSelectedView] = useState('dashboard');

    const handleViewChange = (view) => {
      setSelectedView(view);
      onSelectView(view);
    };

    
  return (
    <div className="sidebar">
    <h2>Sidebar</h2>
    <ul>
      <li onClick={() => handleViewChange('dashboard')}>Dashboard</li>
      <li onClick={() => handleViewChange('settings')}>Settings</li>
      <li onClick={() => handleViewChange('addNewBill')}>Add New Bill</li>
      
    </ul>
  </div>

  );
};

export default Sidebar;
