// InvoiceForm.js

import React, { useState } from 'react';
import './AddNewBill.css';

const AddNewBill = () => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    phone: '',
    fatherName: '',
    googleAddress: '',
    items: '',
    paymentMode: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can handle the form submission logic here
    console.log('Form Data:', formData);
  };

  const [rows, setRows] = useState([
    // Add more rows as needed
  ]);
  const [newRow, setNewRow] = useState( {  description: '', quantity: 0, unitPrice: 0 });

  const handleInputChange = ( field, value) => {
   


     setNewRow({ ...newRow, [field]: value });
    // setRows(updatedRows);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };
  const handleAddRow = () => {
    setRows([...rows,
        {  description: newRow.description, quantity: newRow.quantity, unitPrice: newRow.unitPrice }]);

       setNewRow( { description: '', quantity: 0, unitPrice: 0 })
  };

  const calculateTotal = () => {
    return rows.reduce((sum, row) => sum + (row.quantity * row.unitPrice), 0);
  };


  return (
    <div id="invoice">
     
    <h2>Invoice</h2>
    <h1>  Raji Agri Farm - Poplar Nursery Farm </h1>

    <div id="buyerSellerDiv">
      <div id="BillNoDiv">
        <p class="p_margin2">Invoice Number: [Invoice Number]</p>
      </div>
      <div id="DateDiv">
        <p class="p_margin2">Date: [Date]</p> 
        <p class="p_margin2">Time: [Date]</p>
      </div>
    </div>


    <div id="buyerSellerDiv">
   
      <div id="SellerDiv">

          <table class="classTablefontAddress">
              <tr>
               <td colspan="2" class="classTablefontAddress_td"><h4 class="h4_heading">Buyer Detail</h4></td>
                </tr>
                <tr>
                  <td class="classTablefontAddress_td" > Name </td>
                 
                  <td class="classTablefontAddress_td" >  
                  <input className='inputAddBillGeneric'
                   type="text"
                   name="googleAddress"  />
                    </td>

                  {/* value={formData.googleAddress} onChange={handleChange} */}
                </tr>
                <tr>
                  <td class="classTablefontAddress_td" > Son of</td>
                 <td class="classTablefontAddress_td" >  
                  <input className='inputAddBillGeneric'
                   type="text"
                   name="googleAddress"  />
                    </td>
                </tr>
                <tr>
                  <td class="classTablefontAddress_td" > Phone</td>
                  <td class="classTablefontAddress_td" >  
                  <input className='inputAddBillGeneric'
                   type="text"
                   name="googleAddress"  />
                    </td>
                </tr>
                <tr>
                  <td class="classTablefontAddress_td"> Other Contact </td>
                  <td class="classTablefontAddress_td" >  
                  <input className='inputAddBillGeneric'
                   type="text"
                   name="googleAddress"  />
                    </td>
                </tr>
                <tr>
                  <td class="classTablefontAddress_td"> Phone </td>
                  <td class="classTablefontAddress_td" >  
                  <input className='inputAddBillGeneric'
                   type="text"
                   name="googleAddress"  />
                    </td>
                </tr>
                <tr>
                  <td class="classTablefontAddress_td" >Village </td>
                  <td class="classTablefontAddress_td" >  
                  <input className='inputAddBillGeneric'
                   type="text"
                   name="googleAddress"  />
                    </td>
                </tr>
                <tr>
                  <td class="classTablefontAddress_td" >Town </td>
                  <td class="classTablefontAddress_td" >  
                  <input className='inputAddBillGeneric'
                   type="text"
                   name="googleAddress"  />
                    </td>
                </tr>
                <tr>
                  <td class="classTablefontAddress_td">Teh:  </td>
                  <td class="classTablefontAddress_td" >  
                  <input className='inputAddBillGeneric'
                   type="text"
                   name="googleAddress"  />
                    </td>
                </tr>
                <tr>
                  <td class="classTablefontAddress_td"> District : </td>
                  <td class="classTablefontAddress_td" >  
                  <input className='inputAddBillGeneric'
                   type="text"
                   name="googleAddress"  />
                    </td>
                </tr>

               

          </table>
      
        <br />
       
        <br />
      </div>
    </div>
    <table>
        <thead>
          <tr>
            <th>Sr. No</th>
            <th>Item Description</th>
            <th>Quantity</th>
            <th>Unit Price</th>
            <th>Total</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{row.description}</td>
              <td>{row.quantity}</td>
              <td>{row.unitPrice}</td>
              <td>{row.quantity * row.unitPrice}</td>
              <td>
              <button onClick={() => handleRemoveRow(index)}>Cross</button>
              </td>
            </tr>
          ))}
          {/* Conditionally render input fields for the last row */}
          <tr>
              <td>
                <input
                  type="text"
                   value={rows.length +1}
                  
                />
              </td>
              <td>
                <input
                  type="text"
                  value={newRow.description}
                  onChange={(e) => handleInputChange( 'description', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={newRow.quantity}
                  onChange={(e) => handleInputChange( 'quantity', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={newRow.unitPrice}
                  onChange={(e) => handleInputChange( 'unitPrice', e.target.value)}
                />
              </td>
              <td>
               
               **** 
              </td>
              <td>
              <button onClick={handleAddRow}>Add</button>
              </td>
            </tr>
        </tbody>
      </table>
    <div class="total">
      <p><strong>Total: </strong> {calculateTotal()} </p>
    </div>

    <table>
      <tr>
          <td> Date </td>
          <td> Mode  </td>
          <td> Given Amount</td>
          <td> Balance </td>
      </tr>

      <tr>
          <td> 30 Agust </td>
          <td> CASh  </td>
          <td> 5000</td>
          <td> 80000 </td>
      </tr>

      <tr>
          <td> 30 Agust </td>
          <td> CASh  </td>
          <td> 2000</td>
          <td> 60000 </td>
      </tr>

      <tr>
          <td> 30 Agust </td>
          <td> PTM  </td>
          <td> 60000</td>
          <td> 00 </td>
      </tr>

    </table>

 

    <div>
      <h4 class="h4_heading">Extra notes</h4>
      <li> Transport [For] added in above item</li>
    </div>
    <hr/>
    <div> 
      1. Goods once sold will not be returned. <br/>
      2. All Subject to ROPAR jurisdictions only E.&OE

    </div>

  </div>
  );
};

export default AddNewBill;



