// src/PrintButton.js
import React from 'react';
import html2pdf from 'html2pdf.js';

const PrintButton = ({ contentId }) => {
  const handlePrint = () => {

    if (window.confirm("Are you sure you want to  Save the pdf?")) {
        const content = document.getElementById(contentId);

        if (content) {
          const pdfOptions = {
            margin: 10,
            filename: 'your_document.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
          };
    
          html2pdf(content, pdfOptions);
        }
      }
  };

  return (
    <button onClick={handlePrint}>
      Print or Save as PDF
    </button>
  );
};

export default PrintButton;
