import React from "react";
import "./TextBanner.css";
import {
  RFarm_Title_2,
  RFarm_Title,
  RFarm_poplarTileTitle_2,
  LifeMemberPau,
} from "./GerenicView.js";

function TextBanner() {
  return (
    // <span className= "rajiFarmTile1_Span"> Raji Farm </span>
    <div className="TextBannerAttribute">
      <marquee direction="left">
        Booking of Poplar Nursery
        <span className="clones"> (110,109,G48,PL2,Othe)</span> is started, 📞
        Call Raji- <a href="tel://9814164511">9814164511 </a> , Ajay-
        <a href="tel://9914999433">9914999433 </a>, Vijay-
        <a href="tel://7347200037">7347200037 </a>...... we welome you at{" "}
        <RFarm_Title_2 />
      </marquee>
    </div>
  );
}

//   function RFarm_Title_2() {
//     return (
//       <span className= "clones"> Raji Farm </span>
//     )
//   }

export { TextBanner };
